// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  //  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: light) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    //  --ion-background-color: #000000;
    // --ion-background-color-rgb: 0,0,0;
    //   --ion-background-color: #536570;
    --ion-background-color: url('../assets/bacground.jpg') no-repeat;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    //  --ion-background-color: #121212;
    //  --ion-background-color: #536570;
    //   --ion-background-color-rgb: 18,18,18;
    --ion-background-color: url('../assets/bacground.jpg') no-repeat;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

/*

.btn-menu{
//  width: 14vw!important;
  border-radius: 20rem !important;
  min-height: 30vh !important;
  min-width: 18vw;
  background-color: #17a2b8 !important;
  padding: 2rem !important;
  margin: 2.8% !important;
}

@media (max-width: 500px) {
  .btn-menu{
    //  width: 14vw!important;
    min-height: 30vh !important;
    border-radius: 20rem !important;
    min-width: 40vw!important;
    margin: auto!important;
    margin-top: 6% !important;
  }
}
*/


.m-auto {
  margin: auto !important;
}


.mar-web-doc {
  margin: auto;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
}

.mar-sm-doc {
  margin: auto;
  margin-top: 4% !important;
  margin-bottom: 1.5% !important;
}


.min-h49 {
  min-height: 49vh !important;
}

.min-w49 {
  min-width: 49% !important;
}

.min-w45 {
  min-width: 45% !important;
}

.i-max-cont {
  max-width: max-content !important;
}

.w-fit {
  width: fit-content !important;
}

.w98 {
  width: 98% !important;
}

.h-fit {
  height: fit-content !important;
}


.color-i-w {
  color: white !important;
}

.t-ali-center {
  text-align: center !important;
}

.t-ali-lef {
  text-align: left !important;
}

.t-ali-rigth {
  text-align: right !important;
}

.item-sp-bw {
  display: flex !important;
  justify-content: space-between !important;
}

.item-align-col {
  display: flex !important;
  flex-direction: column !important;
}

.item-align-row {
  display: flex !important;
  flex-direction: row !important;
}

.i-al-lef {
  display: flex !important;
  justify-content: flex-start !important;
}

.i-al-ri {
  display: flex !important;
  justify-content: flex-end !important;
}

.i-al-center {
  display: flex !important;
  justify-content: center !important;
}

.i-s-bw {
  display: flex !important;
  justify-content: space-between !important;
}

.w100 {
  width: 100% !important;
}

.w-herith {
  width: inherit !important;
}

.b-none {
  border: none !important;
}

.bc-none {
  background: none !important;
}

.bc-doc-step {
  // background-color: #f4f9ff63 !important;
}

.w90 {
  width: 90% !important;
}

.w95 {
  width: 95% !important;
}

.w96 {
  width: 96% !important;
}

.w97 {
  width: 97% !important;
}

.w98 {
  width: 98% !important;
}

.w99 {
  width: 99% !important;
}

.w80 {
  width: 80% !important;
}

.w85 {
  width: 85% !important;
}

.w70 {
  width: 70% !important;
}

.w75 {
  width: 75% !important;
}

.w-60 {
  width: 60% !important;
}

.w50 {
  width: 50% !important;
}

.w40 {
  width: 40% !important;
}

.w45 {
  width: 45% !important;
}

.w49 {
  width: 49% !important;
}


.w30 {
  width: 30% !important;
}

.w20 {
  width: 20% !important;
}

.w10 {
  width: 10% !important;
}

.w11 {
  width: 11% !important;
}

.w12 {
  width: 12% !important;
}

.w9 {
  width: 9% !important;
}

.w8 {
  width: 8% !important;
}

.w6 {
  width: 6% !important;
}

.w5 {
  width: 5% !important;
}

.w4 {
  width: 4% !important;
}

.w3 {
  width: 3% !important;
}

.w2 {
  width: 2% !important;
}

.w1 {
  width: 1% !important;
}


.h100 {
  height: 100% !important;
}

.h90 {
  height: 90% !important;
}

.h80 {
  height: 80% !important;
}

.h70 {
  height: 70% !important;
}

.h60 {
  height: 60% !important;
}

.w50 {
  height: 50% !important;
}

.h40 {
  height: 40% !important;
}

.h30 {
  height: 30% !important;
}

.h20 {
  height: 20% !important;
}

.h10 {
  height: 10% !important;
}

.h5 {
  height: 5% !important;
}

.m-auto {
  margin: auto !important;
}

.h-herit {
  height: inherit !important;
}

.marg1 {
  margin: 1% !important;
}

.marg2 {
  margin: 2% !important;
}

.ma-t-1 {
  margin-top: 1% !important;
}

.mar-01 {
  margin: 0.1% !important;
}

.mar-02 {
  margin: 0.2% !important;
}

.mar-03 {
  margin: 0.3% !important;
}

.mar-04 {
  margin: 0.4% !important;
}

.mar-05 {
  margin: 0.5% !important;
}

.mar-06 {
  margin: 0.6% !important;
}

.mar-07 {
  margin: 0.7% !important;
}

.mar-08 {
  margin: 0.8% !important;
}

.mar-09 {
  margin: 0.9% !important;
}

.marg3 {
  margin: 3% !important;
}

.marg1-5 {
  margin: 1.5% !important;
}

.size1 {
  font-size: 1rem !important;
}

.size05 {
  font-size: 0.5rem !important;
}

.size06 {
  font-size: 0.6rem !important;
}

.size07 {
  font-size: 0.7rem !important;
}

.size08 {
  font-size: 0.8rem !important;
}

.size09 {
  font-size: 0.9rem !important;
}

.size11 {
  font-size: 1.1rem !important;
}

.size12 {
  font-size: 1.2rem !important;
}

.size2 {
  font-size: 2rem !important;
}

.size2-5 {
  font-size: 2.5rem !important;
}

.i-curs-p {
  cursor: pointer !important;
}


.back-style-yme {
  background: rgb(29, 60, 73) !important;
  // background: linear-gradient(0deg, rgba(29, 60, 73, 1) 8%, rgba(57, 152, 211, 1) 63%, rgba(116, 197, 146, 1) 96%) !important;
  // width: 100% !important;
}

.br-0-3 {
  border-radius: 0.3% !important;
}

.h-70 {
  height: 70vh !important;
}

.h-80 {
  height: 80vh !important;
}

.h-85 {
  height: 85vh !important;
}

.bc-bloc-col {
  background-color: #4f5358cf !important;
}

.bc-accueil {
  background-image: url('../assets/img/secu.jpg') !important;
}

.bc-tab-i {
  color: black !important;
  background: #f6fffc;
  border-radius: 0.3rem !important;
}
